import * as React from "react"

import ToolScreen from '../../../../components/toolScreen'


const Tool = ({ data }) => (
  <ToolScreen
    name="Adobe Photoshop"
    img_name="adobe_photoshop_logo.png"
    link="https://www.adobe.com/photoshop/"
    description="Adobe Photoshop is still the best solution for professional designers looking for advanced photo editing features."
    twitterHandleTool="@Photoshop"
  />
)

export default Tool;


